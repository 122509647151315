var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"layout"},[_c('Navbar'),_c('el-container',[_c('el-main',{staticClass:"panel"},[_c('el-card',{attrs:{"shadow":"always"}},[_c('div',{staticClass:"header"},[_c('el-breadcrumb',{staticClass:"breadcrumb",attrs:{"separator":"/"}},[_c('el-breadcrumb-item',{attrs:{"to":{ path: '/dashboard' }}},[_vm._v("Home")]),_c('el-breadcrumb-item',[_vm._v("DNSRecords")])],1),_c('div',{staticClass:"btn"},[_c('el-button',{attrs:{"type":"primary","plain":""},on:{"click":function($event){return _vm.$router.push({
                  name: 'DnsrecordCreate',
                  params: { zone_id: _vm.$route.params.zone_id },
                })}}},[_vm._v("添加记录")])],1)],1),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"height":"30rem","border":"","data":_vm.dnsrecords}},[_c('el-table-column',{attrs:{"prop":"type","label":"记录类型","sortable":"","width":"180"}}),_c('el-table-column',{attrs:{"prop":"name","label":"记录名","sortable":""}}),_c('el-table-column',{attrs:{"prop":"content","label":"记录值","sortable":""}}),_c('el-table-column',{attrs:{"prop":"ttl","label":"TTL","sortable":"","width":"100"}}),_c('el-table-column',{attrs:{"prop":"proxied","label":"CDN","sortable":"","width":"100","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.proxied)?_c('i',{staticClass:"el-icon-lightning proxied active",on:{"click":function($event){return _vm.change_proxied(scope.row, $event)}}}):_c('i',{staticClass:"el-icon-lightning proxied",on:{"click":function($event){return _vm.change_proxied(scope.row, $event)}}})]}}])}),_c('el-table-column',{attrs:{"prop":"actions","label":"操作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button-group',[_c('el-button',{attrs:{"size":"mini"},on:{"click":function($event){return _vm.copy2clipboard(scope.row.name + '.cdn.cloudflare.net')}}},[_vm._v("Copy")]),_c('el-button',{attrs:{"size":"mini"},on:{"click":function($event){return _vm.$router.push({
                      name: 'DnsrecordEdit',
                      params: {
                        zone_id: scope.row.zone_id,
                        record_id: scope.row.id,
                      },
                    })}}},[_vm._v("Manage")]),_c('el-popconfirm',{attrs:{"title":("确定删除" + (scope.row.name) + "吗？")},on:{"confirm":function($event){return _vm.delete_dnsrecord(scope.row)}}},[_c('el-button',{attrs:{"slot":"reference","size":"mini","type":"danger"},slot:"reference"},[_vm._v("Delete")])],1)],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }